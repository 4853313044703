const $ = require('jquery');
global.$ = global.jQuery = $;
const _ = require('lodash');
global._ = _;

const summernote = require('summernote');
global.summernote = summernote;
global.setSessionDataUrl = 'participants-set-session';

import 'jquery.mousewheel';
import './template/jquery-2.1.4.min.js';
import './template/jquery-2.1.4.min.js';
import './template/jquery-ui.min.js';
import './template/jquery.dataTables.min.js';
import './template/jquery.doubleScroll.js';
import './template/freeze-table.js';
import './template/dataTables.tableTools.min.js';
import './template/dataTables.colReorder.min.js';
import './template/dataTables.bootstrap.js';
import './template/bootstrap-multiselect.js';
import './template/jquery-ui-timepicker.js';
import './template/footable.all.min.js';
import './template/select2.min';
import './template/custom_functions.min.js';
import './template/datatableSessionStorage.js';
