
var selectedIds = [];

window.dataTableCallbacks = function(tableId, checkboxClass, selectAllButtonClass) {
    selectedIds = window.getFromLocalStorage(tableId);
    window.markCheckboxes(selectedIds, tableId, checkboxClass);
    window.getSelectedCount(selectedIds);
    window.listenerCheckboxAction(tableId, checkboxClass)
    window.listenerClearSelectedParticipants(tableId, checkboxClass);
    window.selectAllButton(tableId, checkboxClass, selectAllButtonClass);
}

window.selectAllButton = function(tableId, checkboxClass, selectAllButtonClass) {
    $('.' + selectAllButtonClass).change(function () {
        var status = $(this).is(":checked") ? true : false;
        $('#' + tableId + ' .' + checkboxClass).prop("checked", status).change();
    });
}
window.listenerSendAction = function (tableId, checkboxClass) {
    $('.action-with-selected').on('click', function() {
        $('#action-select-ids').val(localStorage.getItem(tableId))
        $('#action-select-type').val($(this).attr('data-type'))
        $('#selectedIdsForm').submit();
        selectedIds = [];
        $('#'+tableId+' .'+checkboxClass).prop('checked', false);
        $('.selectAllButton').prop('checked', false);
        window.saveToLocalstorage(tableId, selectedIds);
        window.getSelectedCount(selectedIds);
    });
}

window.listenerCheckboxAction = function (tableId, checkboxClass) {
    $('#'+tableId+' .'+checkboxClass).change(function() {
        if ($(this).is(':checked')) {
            if (jQuery.inArray($(this).val(), selectedIds) === -1) {
                selectedIds.push($(this).val());
            }
        } else {
            if ($(this).val() != undefined) {
                var index = selectedIds.indexOf($(this).val());
                if (index > -1) {
                    selectedIds.splice(index, 1);
                }
            }
        }
        window.saveToLocalstorage(tableId, selectedIds);
        window.getSelectedCount(selectedIds);
    });
}

window.saveToLocalstorage = function (tableId, selectedIds) {
    localStorage.setItem(tableId, selectedIds);
}

window.getFromLocalStorage = function (tableId) {
    var localstorageIds = localStorage.getItem(tableId);
    if (localstorageIds == null || localstorageIds == undefined) {
        return [];
    }
    localstorageIds = localstorageIds.split(',');
    localstorageIds = window.filterEmptyArray(localstorageIds);
    return localstorageIds;
}

window.getSelectedCount = function() {
    if (selectedIds.length > 0) {
        $('#selectedParticipants').show();
        $('#selectedCount').text(selectedIds.length)
    } else {
        $('#selectedParticipants').hide();
        $('#selectedCount').text(0)
    }
}

window.markCheckboxes = function (selectedIds, tableId, checkboxClass) {
    if (Array.isArray(selectedIds) && selectedIds.length) {
        $(selectedIds).each(function(index, participantId){
            if (participantId > 0) {
                $('#'+tableId+' .'+checkboxClass+'[value='+participantId+']').prop('checked', true);
            }
        })
    }
}
window.listenerClearSelectedParticipants = function(tableId, checkboxClass) {
    $('.clear-selected-participants').on('click', function () {
        selectedIds = [];
        $('#'+tableId+' .'+checkboxClass).prop('checked', false);
        $('.selectAllButton').prop('checked', false);
        window.saveToLocalstorage(tableId, selectedIds);
        window.getSelectedCount(selectedIds);
    });
}

window.filterEmptyArray = function(array) {
    array = array.filter(function (el) {
        return el != null && el != '';
    });
    array = array.filter( onlyUnique )
    return array;
}
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
