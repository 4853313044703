/**
 * Created by Piotr on 1/16/2018.
 */

var collectionIds = [];

window.dataTablesCallbacks  = function(tableClass, checkboxClass, resultIdArray, selectAllClass) {

    window.massiveCheckbox(tableClass, checkboxClass, resultIdArray);
    window.selectAllOld(tableClass, checkboxClass, selectAllClass);
}


/**
 * Function gather objects ids to one element
 * params:
 * @ tableClass - area of clicable checkboxes
 * @ checkboxClass - which class of checkbox is clicable
 * @ resultIdArray - array of ids  elements to output value
 *
 * */
window.massiveCheckbox = function(tableClass, checkboxClass, resultIdArray){
    $('.'+tableClass+' .'+checkboxClass).change(function(){
        if($(this).is(':checked')) {
            collectionIds.push($(this).val());
        } else {
            if($(this).val() != undefined) {
                var index = collectionIds.indexOf($(this).val());
                if (index > -1) {
                    collectionIds.splice(index, 1);
                }
            }
        }
        window.setArrayToValue(resultIdArray, collectionIds);
    });
    console.log('massiveCheckbox');
    console.log(collectionIds);

}

window.selectAllOld = function(tableClass, checkboxClass, selectAllClass) {

    $('.'+selectAllClass).change(function(){
        collectionIds = [];
        var status = $(this).is(":checked") ? true : false;
        $('.'+tableClass+' .'+checkboxClass).prop("checked",status).change();
    });
    console.log('selectAll');
    console.log(collectionIds);
}

window.setArrayToValue = function(resultIdArray, dataArray) {
    stringData = dataArray + "";
    $.each(resultIdArray, function( index, value ) {
        $('#'+value).val(stringData);
    });
}

window.redirectToParticipant = function() {
    var hash = location.hash.replace("#","");
    if(hash) {
        if ($("#"+hash).length) {
            $('html, body').animate({
                scrollTop: $("#"+hash).offset().top
            }, 1);
        }
        if ($("#"+hash).length) {
            $("#"+hash).closest('tr').find('td').each(function () {
                $(this).css('background-color', '#d9f1d5');
            });
        }
    }
}

$.datepicker.regional['pl'] = {
    closeText: "Zamknij",
    prevText: "&#x3C;Poprzedni",
    nextText: "Następny&#x3E;",
    currentText: "Dziś",
    monthNames: [ "Styczeń","Luty","Marzec","Kwiecień","Maj","Czerwiec",
        "Lipiec","Sierpień","Wrzesień","Październik","Listopad","Grudzień" ],
    monthNamesShort: [ "Sty","Lu","Mar","Kw","Maj","Cze",
        "Lip","Sie","Wrz","Pa","Lis","Gru" ],
    dayNames: [ "Niedziela","Poniedziałek","Wtorek","Środa","Czwartek","Piątek","Sobota" ],
    dayNamesShort: [ "Nie","Pn","Wt","Śr","Czw","Pt","So" ],
    dayNamesMin: [ "N","Pn","Wt","Śr","Cz","Pt","So" ],
    weekHeader: "Tydz",
    dateFormat: "dd.mm.yy",
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: "" };
$.datepicker.setDefaults($.datepicker.regional['pl']);

